<template>
  <b-card>
    <g-table
      ref="temporaryStudents-table"
      :items="items"
      :columns="tableColumns"
      @filtered="onFiltered"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false}"
      :searchInput="{ visiable: true }"
    >
      <template #actions="{ item }">
        <div class="text-nowrap">

          <!-- view button -->
          <b-button
            v-b-tooltip.hover.top="$t('viewStudentRegistrationDetails')"
            v-permission="'viewRegistrationRequests'"
            data-action-type="edit"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              icon="EyeIcon"
              class="mx-25 clickable"
            />
          </b-button>

          <!-- accept registration request button -->
          <b-button
            v-if="item.status === 'waiting'"
            v-b-tooltip.hover.top="$t('acceptStudentRegistration')"
            v-permission="'acceptRegistrationRequest'"
            variant="flat-success"
            class="btn-icon"
            size="sm"
            @click="openItemEducationalStages(item)"
          >
            <feather-icon
              icon="CheckIcon"
              stroke="green"
            />
          </b-button>
        </div>
      </template>
    </g-table>

    <!-- pop-up menu for educational stages -->
    <b-row>
      <b-modal
        ref="stages-model"
        no-close-on-backdrop
        cancel-variant="outline-secondary"
        centered
        size="sm"
        hide-footer
        :title="$t('acceptStudentRegistration')"
      >
        <b-row>
          <!-- stage -->
          <b-col md="12">
            <b-form-group>
              <g-field
                v-model="selectedItem.stageId"
                :dir="isRight ? 'rtl' : 'ltr'"
                field="select"
                name="stages"
                :options="currentStages"
                :label="isRight ? 'arabicName' : 'englishName'"
                label-text="stage"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- row -->
          <b-col md="12">
            <b-form-group>
              <g-field
                v-model="selectedItem.rowId"
                :dir="isRight ? 'rtl' : 'ltr'"
                field="select"
                name="row"
                :options="currentRows"
                :label="isRight ? 'arabicName' : 'englishName'"
                label-text="row"
                :filter-fn="(item) => item.stageId === selectedItem.stageId"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- classRoom  -->
          <b-col md="12">
            <b-form-group>
              <g-field
                v-model="selectedItem.classroomId"
                :dir="isRight ? 'rtl' : 'ltr'"
                field="select"
                name="classRoom"
                :options="currentClassrooms"
                :label="isRight ? 'arabicName' : 'englishName'"
                label-text="classRoom"
                :filter-fn="(item) => item.row === selectedItem.rowId"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- accept button -->
        <b-row>
          <b-col
            md="12"
            class="d-flex justify-content-center"
          >
            <b-button
              variant="relief-primary"
              @click="acceptRegistration()"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
              />
              {{ this.$t('acceptStudentRegistration') }}
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';
import { rows } from '@/libs/acl/Lookups';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    GTable,
  },
  mixins: [reportMixin],
  data() {
    return {
        items: [],
        currentStages: [],
        currentRows: [],
        currentClassrooms: [],
        selectedItem: {
          stageId: null,
          rowId: null,
          classroomId: null
        },
        totalRows: 0,
        currentPage: 1,
        perPage: 25,
        rows: rows,
        modal: false,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'serialNo', label: this.$t('serialIndicator'), sortable: true },
        { key: 'registrationDate', label: this.$t('registrationDate'), sortable: true },
        { key: 'arabicName', label: this.$t('arabicName'), sortable: true },
        { key: 'mobileNumber', label: this.$t('mobile'), sortable: true },
        { key: 'nationalityArabicName', label: this.$t('nationality'), sortable: true },
        { key: 'stageArabicName', label: this.$t('stage'), sortable: true },
        { key: 'rowArabicName', label: this.$t('row'), sortable: true },
        {
          key: 'status',
          field: 'status',
          label: this.$t('status'),
          sortable: false,
          formatter: (key, value, item) => {
            if (item.status === 'waiting') {
              return `<span class="p-50 main-space badge bg-gradient-danger bg-darken-1"> ${this.$t(
                'newRegistration'
              )}</span>`;

            // otherwise
            }
            return `<span class="p-50 main-space badge bg-gradient-success bg-darken-1"> ${this.$t(
                'registrationAccepted'
              )}</span>`;
          },
        },
        { key: 'actions' },
      ];
    },
  },

  mounted() {
    this.getItems();
    this.loadObjects();
  },

  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getItems() {
      this.get({ url: 'RegistrationRequests' }).then((data) => {
        this.items = data;
        this.items.forEach(element => {
            element.registrationDate = this.getDate(element.registrationDate);
        });
      });
    },

    edit(item) {
      this.$router.push({
        name: 'temporaryStudents-edit',
        params: { id: item.id },
      });
   },

    loadObjects() {
      this.get({ url: 'Stages' }).then((data) => {
        this.currentStages = data;
      });
      this.get({ url: 'Rows' }).then((data) => {
        this.currentRows = data;
      });
      this.get({ url: 'Classrooms/getClassroomsLookupWithEducationlIds' }).then((data) => {
        this.currentClassrooms = data;
      });
    },

    getCurrentItemById(id) {
      this.get({ url: `RegistrationRequests/${id}` }).then((data) => {
        this.selectedItem = data;
      });
    },

    openItemEducationalStages(item) {
      this.$refs['stages-model'].show();
      this.getCurrentItemById(item.id);
    },

    classCheck() {
      if (!this.selectedItem.classroomId) {
        this.doneAlert({ text: this.$t('classIsRequired'), type: 'warning' });
        return false;
      }
      return true;
    },

    acceptRegistration() {
      if (!this.classCheck()) return;
      this.create({ url: 'RegistrationRequests/AcceptRegistrationRequest', data: this.selectedItem })
      .then(() => {
        this.doneAlert({ text: this.$t('registrationAccepted') });
        this.$refs['stages-model'].hide();
        const item = this.items.find((val) => val.id === this.selectedItem.id);
        item.status = 'accepted';
      });
    }
  },
};
</script>

<style></style>
